import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/services/auth-guard';
import { TrackingComponent } from './external/tracking/tracking.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (a) => a.AuthenticationModule
      ),
    component: LayoutComponent,
  },
  {
    path: 'link-external/:prefix/:clientId/:ofertaId/:hash',
    loadChildren: () =>
      import('./external/tracking.module').then(
        (m) => m.TrackingModule
      ),
    component: TrackingComponent
  },
  {
    path: 'configurations-carrier',
    loadChildren: () =>
      import('./configurations-carrier/configurations-carrier.module').then(
        (c) => c.ConfigurationsCarrierModule
      ),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [
        {
          label: 'Perfil',
          items: [
            {
              label: 'Meu Perfil',
              path: 'profile',
              icon: 'user-line',
              breadcrumb: 'Meu Perfil',
            },
            {
              label: 'Minhas empresas',
              path: 'companies',
              icon: 'building-4-line',
              breadcrumb: 'Minhas empresas',
            },
            {
              label: 'Cadastro de empresa',
              path: 'new-company',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de empresa',
            },
            {
              label: 'Cadastro de empresa',
              path: 'edit-company',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de empresa',
            },
            {
              label: 'Meus usuários',
              path: 'users',
              icon: 'user-6-line',
              breadcrumb: 'Meus usuários',
            },

            {
              label: 'Cadastro de usuário',
              path: 'new-user',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de usuário',
            },
            {
              label: 'Editar usuário',
              path: 'edit-user',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar usuário',
            },
            {
              label: 'Tipos de usuário',
              path: 'user-types',
              icon: 'file-lock-line',
              breadcrumb: 'Tipos de usuário',
            },
            {
              label: 'Privilégios de acesso',
              path: 'access-privileges',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Privilégios de acesso',
            },
            {
              label: 'Privilégios de acesso',
              path: 'edit-access-privileges',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Privilégios de acesso',
            },
          ],
        },
      ],
      childrens: [],
    },
  },
  {
    path: 'configurations-shipper',
    loadChildren: () =>
      import('./configurations-shipper/configurations-shipper.module').then(
        (c) => c.ConfigurationsShipperModule
      ),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [
        {
          label: 'Perfil',
          items: [
            {
              label: 'Meu Perfil',
              path: 'profile',
              icon: 'user-line',
              breadcrumb: 'Meu Perfil',
            },
            {
              label: 'Minhas empresas',
              path: 'companies',
              icon: 'building-4-line',
              breadcrumb: 'Minhas empresas',
            },
            {
              label: 'Cadastro de empresa',
              path: 'new-company',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de empresa',
            },
            {
              label: 'Editar empresa',
              path: 'edit-company',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar empresa',
            },
            {
              label: 'Meus usuários',
              path: 'users',
              icon: 'user-6-line',
              breadcrumb: 'Meus usuários',
            },
            {
              label: 'Cadastro de usuário',
              path: 'new-user',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de usuário',
            },
            {
              label: 'Editar usuário',
              path: 'edit-user',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar usuário',
            },
            {
              label: 'Tipos de usuário',
              path: 'user-types',
              icon: 'file-lock-line',
              breadcrumb: 'Tipos de usuário',
            },
            {
              label: 'Meus destinatários',
              path: 'recipients',
              icon: 'map-pin-range-line',
              breadcrumb: 'Meus destinatários',
            },
            {
              label: 'Detalhes do destinatário',
              path: 'details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes do destinatário',
            },
            {
              label: 'Cadastro de destinatário',
              path: 'new-recipient',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de destinatário',
            },
            {
              label: 'Editar destinatário',
              path: 'edit-recipient',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar destinatário',
            },
            {
              label: 'Meus fornecedores',
              path: 'suppliers',
              icon: 'service-line',
              breadcrumb: 'Meus fornecedores',
            },
            {
              label: 'Detalhes do fornecedor',
              path: 'supplier-details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes do fornecedor',
            },
            {
              label: 'Cadastro de fornecedor',
              path: 'new-supplier',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Cadastro de fornecedor',
            },
            {
              label: 'Editar fornecedor',
              path: 'edit-supplier',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar fornecedor',
            },
            {
              label: 'Privilégios de acesso',
              path: 'access-privileges',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Privilégios de acesso',
            },
            {
              label: 'Privilégios de acesso',
              path: 'edit-access-privileges',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Privilégios de acesso',
            },
            {
              label: 'Integrações',
              path: 'integrations',
              icon: 'code-line',
              breadcrumb: 'Configurações',
            },
            {
              label: 'Oferta Contrato',
              path: 'offer-contract',
              icon: 'draft-line',
              breadcrumb: 'Configurações',
            },
            {
              label: 'Regras de ambiente',
              path: 'environment-rules',
              icon: 'bookmark-line',
              breadcrumb: 'Regras de ambiente',
            },
          ],
        },
        {
          label: 'Pagamentos',
          items: [
            {
              label: 'Minha conta',
              path: 'my-account',
              icon: 'profile-line',
              breadcrumb: 'Meu conta',
            },
          ]
        }
      ],
      childrens: [],
    },
  },
  {
    path: 'carriers',
    loadChildren: () =>
      import('./carrier/carrier.module').then((ca) => ca.CarrierModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [
        {
          label: 'Meus parceiros',
          items: [
            {
              label: 'Meus embarcadores',
              path: 'my-shippers',
              icon: 'star-s-line',
              breadcrumb: 'Meus embarcadores',
            },
            {
              label: 'Meus convites',
              path: 'my-shipper-invitations',
              icon: 'mail-line',
              breadcrumb: 'Meus convites',
            },
            {
              label: 'Detalhes da empresa',
              path: 'company-details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes da empresa',
            },
            {
              label: 'Ofertas',
              path: 'offers',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Ofertas',
            },
            {
              label: 'Detalhes da oferta',
              path: 'offer-details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes da oferta',
            },
            {
              label: 'Tutoriais',
              path: 'tutorials',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Tutoriais',
            },
            {
              label: 'Home',
              path: 'carriers',
              hideNav: true,
              hidden: true,
              home: true,
            },
          ],
        },
      ],
      childrens: [],
    },
  },
  {
    path: 'shipper',
    loadChildren: () =>
      import('./shipper/shipper.module').then((ca) => ca.ShipperModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [
        {
          label: 'Meus parceiros',
          items: [
            {
              label: 'Explorar transportadoras',
              path: 'explore-carriers',
              icon: 'user-search-fill',
              breadcrumb: 'Explorar transportadoras',
            },
            {
              label: 'Convidar transportadora',
              path: 'carrier-invite',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Convidar transportadora',
            },
            {
              label: 'Meus convites',
              path: 'invitations',
              icon: 'mail-line',
              breadcrumb: 'Meus convites',
            },
            {
              label: 'Convites',
              path: 'new-invite',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Convites',
            },
            {
              label: 'Editar convite',
              path: 'edit-invite',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Editar convite',
            },
            {
              label: 'Minhas transportadoras',
              path: 'my-carriers',
              icon: 'star-s-line',
              breadcrumb: 'Minhas transportadoras',
            },
            {
              label: 'Detalhes transportadora',
              path: 'details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes transportadora',
            },
            {
              label: 'Consulta de ofertas de frete',
              path: 'freight-offer',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Consulta de ofertas de frete',
            },
            {
              label: 'Nova Oferta Contrato',
              path: 'contract-offer',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Nova Oferta Contrato',
            },
            {
              label: 'Nova oferta',
              path: 'simplified-offer',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Nova oferta',
            },
            {
              label: 'Novo pedido',
              path: 'order',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Novo pedido',
            },
            {
              label: 'Detalhes da oferta',
              path: 'offer-resume',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Oferta Spot',
            },
            {
              label: 'Lances da oferta',
              path: 'offer-bid',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Lances da oferta',
            },
            {
              label: 'Dashboards',
              path: 'dashboard',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Dashboards',
            },
            {
              label: 'Tutoriais',
              path: 'tutorials',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Tutoriais',
            },
            {
              label: 'Detalhes do pedido',
              path: 'order-details',
              hideNav: true,
              hidden: true,
              breadcrumb: 'Detalhes do pedido',
            },
            {
              label: 'Home',
              path: 'shipper',
              hideNav: true,
              hidden: true,
              home: true,
            },
          ],
        },
        // {
        //   label: 'Critérios de validação',
        //   items: [
        //     {
        //       label: 'Homologação',
        //       path: 'homologation',
        //       icon: 'building-4-line',
        //       breadcrumb: 'Homologação',
        //     },
        //   ],
        // },
      ],
      childrens: [],
    },
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./payments/payments.module').then((p) => p.PaymentsModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [
        {
          label: 'Pagamentos',
          items: [
            {
              label: 'Extrato da fatura',
              path: 'invoice-extract',
              icon: 'file-text-fill',
              breadcrumb: 'Extrato da fatura',
            },
          ],
        },
      ],
      childrens: [],
    },
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('./carrier-portal/carrier-portal.module').then((c) => c.CarrierPortalModule),
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      menuitems: [{
        label: 'Portal do transportador',
        items: [
          {
            label: 'Portal do transportador',
            path: 'portal',
            hideNav: true,
            hidden: true,
            breadcrumb: 'Portal do transportador',
          },
          {
            label: 'Novo CTE',
            path: 'new-cte',
            hideNav: true,
            hidden: true,
            breadcrumb: 'Novo CTE',
          },
          {
            label: 'Editar CTE',
            path: 'edit-cte',
            hideNav: true,
            hidden: true,
            breadcrumb: 'Editar CTE',
          },
          {
            label: 'Detalhes CTE',
            path: 'cte-details',
            hideNav: true,
            hidden: true,
            breadcrumb: 'Detalhes CTE',
          },
        ],
      }],
      childrens: [],
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
